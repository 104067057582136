import { ref, watch } from 'vue'
import { useElementSize } from '@vueuse/core'

export const useElementHeightToCssVariable = (name: string) => {
  const el = ref(null)
  const { height } = useElementSize(el)
  const isDev = useIsDev()
  if (process.client) {
    watch(height, (height, heightPrevious) => {
      if (isDev)
        console.debug(`${name} set to ${height}px`)
      if (height !== heightPrevious)
        updateCssVariable(name, `${height}px`)
    })
  }
  return { el }
}
